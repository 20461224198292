var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('guide-visual', _vm._b({}, 'guide-visual', _vm.visual, false)), _c('div', {
    staticClass: "client-content-wrap"
  }, [_c('scrolldown-tail'), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("정기후원금은 어떻게 사용될까요?")])]), _c('v-row', _vm._l(_vm.businesses, function (business, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "business-card",
      attrs: {
        "max-width": "384",
        "flat": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": business.image
      }
    }), _c('v-card-title', {
      staticClass: "tit tit--sm justify-center primary--text"
    }, [_vm._v(" " + _vm._s(business.title) + " ")]), _c('v-card-text', {
      staticClass: "text-center page-text page-text--lg break-keep-all"
    }, [_vm._v(" " + _vm._s(business.texts) + " ")])], 1)], 1);
  }), 1)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("정기후원에 참여하시면")])]), _c('v-sheet', {
    attrs: {
      "color": "transparent",
      "rounded": "xl"
    }
  }, [_c('v-row', {
    staticClass: "ma-n4"
  }, _vm._l(_vm.services, function (service, index) {
    return _c('v-col', {
      key: service,
      staticClass: "pa-4",
      attrs: {
        "cols": "12",
        "lg": index < 3 ? '4' : '6'
      }
    }, [_c('v-card', {
      staticClass: "service-card",
      attrs: {
        "color": "#FAFAFA",
        "flat": ""
      }
    }, [_c('div', [_c('v-card-title', {
      staticClass: "tit tit--sm justify-center primary--text"
    }, [_vm._v(" " + _vm._s(service.title) + " ")]), _c('v-card-text', {
      staticClass: "page-text page-text--lg text-center"
    }, [_vm._v(" " + _vm._s(service.texts) + " ")]), _c('v-img', {
      staticClass: "mx-auto",
      attrs: {
        "max-width": "60",
        "src": service.image
      }
    })], 1)])], 1);
  }), 1)], 1)], 1)], 1)], 1), _c('section', [_c('v-card', {
    staticClass: "bottom-banner",
    attrs: {
      "tile": "",
      "flat": "",
      "img": "/images/guide/regular/regular-banner.jpg"
    }
  }, [_c('v-container', [_c('h2', {
    staticClass: "bottom-banner__title font-accent"
  }, [_vm._v(" 지금, 여러분의 "), _c('br'), _c('span', [_vm._v("따뜻한 품")]), _vm._v("으로 안아주세요 ")]), _c('v-btn', {
    staticClass: "mt-20 mt-md-40",
    attrs: {
      "href": "https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w",
      "target": "_blank",
      "color": "primary",
      "large": "",
      "rounded": "pill"
    }
  }, [_c('span', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("정기후원하기")]), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }