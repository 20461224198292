<template>
    <section class="guide-visual">
        <v-card :img="image" tile flat class="guide-visual__inner">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h1 class="client-title--xxxl ma-0 line-height-12" :class="white ? 'white--text' : null">
                            {{ title }}
                        </h1>
                    </v-col>
                    <v-col v-if="textImage" cols="12">
                        <v-img :src="textImage.image" :max-width="textImage.size" :alt="textImage.text" />
                    </v-col>
                    <v-col cols="12">
                        <p v-html="texts" class="page-text font-size-md-20" :class="white ? 'white--text' : null" />
                    </v-col>
                    <v-col v-if="button" cols="12">
                        <v-btn color="primary" large rounded="pill" :href="button.link" target="_blank">
                            <span class="font-size-md-20">
                                {{ button.text }}
                            </span>
                            <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <scrolldown :white="white" />
    </section>
</template>

<script>
import scrolldown from "@/components/dumb/scrolldown.vue";
export default {
    components: { scrolldown },
    props: {
        image: String,
        title: String,
        texts: String,
        button: String,
        white: { type: Boolean, default: false },
        textImage: String,
    },
};
</script>

<style lang="scss" scoped>
.guide-visual {
    aspect-ratio: 4/3;
    position: relative;
    &__inner {
        height: 100%;
        display: flex;
        align-items: center;
    }

    h1 {
        color: #624848;
    }

    .scrolldown {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (min-width: 1024px) {
    .guide-visual {
        aspect-ratio: 1920 / 800;
    }
}
</style>