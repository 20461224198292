<template>
    <client-page>
        <guide-visual v-bind="visual" />

        <div class="client-content-wrap">
            <scrolldown-tail />

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">정기후원금은 어떻게 사용될까요?</h2>
                    </div>

                    <v-row>
                        <v-col v-for="(business, index) in businesses" :key="index" cols="12" md="4">
                            <v-card max-width="384" flat outlined class="business-card">
                                <v-img :src="business.image" />
                                <v-card-title class="tit tit--sm justify-center primary--text">
                                    {{ business.title }}
                                </v-card-title>
                                <v-card-text class="text-center page-text page-text--lg break-keep-all">
                                    {{ business.texts }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">정기후원에 참여하시면</h2>
                    </div>

                    <v-sheet color="transparent" rounded="xl">
                        <v-row class="ma-n4">
                            <v-col v-for="(service, index) in services" :key="service" cols="12" :lg="index < 3 ? '4' : '6'" class="pa-4">
                                <v-card color="#FAFAFA" flat class="service-card">
                                    <div>
                                        <v-card-title class="tit tit--sm justify-center primary--text">
                                            {{ service.title }}
                                        </v-card-title>
                                        <v-card-text class="page-text page-text--lg text-center">
                                            {{ service.texts }}
                                        </v-card-text>
                                        <v-img max-width="60" :src="service.image" class="mx-auto" />
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-container>
            </section>
        </div>

        <section>
            <v-card tile flat img="/images/guide/regular/regular-banner.jpg" class="bottom-banner">
                <v-container>
                    <h2 class="bottom-banner__title font-accent">
                        지금, 여러분의 <br />
                        <span>따뜻한 품</span>으로 안아주세요
                    </h2>
                    <v-btn href="https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w" target="_blank" color="primary" large rounded="pill" class="mt-20 mt-md-40">
                        <span class="font-size-18 font-size-md-20">정기후원하기</span>
                        <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                    </v-btn>
                </v-container>
            </v-card>
        </section>
    </client-page>
</template>

<script>
import GuideVisual from "@/components/client/guide/guide-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import ScrolldownTail from "@/components/dumb/scrolldown-tail.vue";

export default {
    components: {
        ClientPage,
        GuideVisual,
        ScrolldownTail,
    },
    data() {
        return {
            visual: {
                image: "/images/guide/regular/visual.jpg",
                title: "정기후원",
                texts: "정기후원은 매월 일정 금액을 후원하는 방법으로, <br />위드캔복지재단의 모든 사업과 활동 중, 가장 도움이 필요한 분야에 <br />우선적으로 지원됩니다.",
            },

            businesses: [
                {
                    image: "/images/guide/regular/business-1.jpg",
                    title: "고유목적 복지사업",
                    texts: "가장 시급한 사업 영역에 최우선적으로 지원되며, 위 사업 외에도 후원자의 관심분야와 지역사회의 필요에따라 다양한 사업을 진행할 수 있습니다.",
                },
                {
                    image: "/images/guide/regular/business-2.jpg",
                    title: "국내복지사업",
                    texts: "편견과 차별속에서, 경제적·의료적 어려움으로 고통받는 소외된 이웃들에게 영유아기부터 노년까지 든든한 버팀목이 될 수 있도록 생애주기별 복지서비스를 제공합니다.",
                },
                {
                    image: "/images/guide/regular/business-3.jpg",
                    title: "고유목적 복지사업",
                    texts: "가장 시급한 사업 영역에 최우선적으로 지원되며, 위 사업 외에도 후원자의 관심분야와 지역사회의 필요에따라 다양한 사업을 진행할 수 있습니다.",
                },
            ],

            services: [
                {
                    title: "첫 감사 안내",
                    texts: "감사의 마음을 담아 후원자님께 감사 문자를 카카오 알림톡으로 발송해드립니다.",
                    image: "/images/guide/regular/service-1.svg",
                },
                {
                    title: "정기 서비스",
                    texts: "정기소식지(반기별)가 발송됩니다. 모바일 또는 이메일 중 원하는 수신 방법을 선택할 수 있습니다.",
                    image: "/images/guide/regular/service-2.svg",
                },
                {
                    title: "기부금 영수증",
                    texts: "기부금 영수증은 국세청 연말정산간소화 서비스를 통해 발급받을 수 있습니다. 단, 주민등록번호가 정확히 등록되어 있어야 이용이 가능합니다.",
                    image: "/images/guide/regular/service-3.svg",
                },
                {
                    title: "연차보고서",
                    texts: "연차보고서(년도별)는 모바일 또는 이메일 중 선택한 수신 방법으로 발송됩니다.소중한 나눔의 결과와 변화된 모습을 확인해 보세요.",
                    image: "/images/guide/regular/service-4.svg",
                },
                {
                    title: "후원자 결과보고서,뉴스레터",
                    texts: "후원자님의 나눔이 이룬 변화를 담은 결과보고서와 함께, 뉴스레터 등 다양한 소식을 비정기적으로 발송해드립니다. 모바일 또는 이메일 중 원하는 수신 방법을 선택하실 수 있습니다.",
                    image: "/images/guide/regular/service-5.svg",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.section {
    position: relative;
}
.business-card {
    border-radius: 16px !important;
    margin: 0 auto;
    height: 100%;
    .v-card {
        &__text {
            word-break: keep-all;
        }
    }
}
.service-card {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 40px;
    .v-card {
        &__title,
        &__text {
            padding-left: 0;
            padding-right: 0;
        }
        &__title {
            padding-top: 0;
        }
        &__text {
            word-break: keep-all;
        }
    }
}
.bottom-banner {
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    padding: 20px 0;
    &__title {
        font-size: 30px;
        > span {
            background-color: #ff8489;
            color: #fff;
            padding: 4px 0;
        }
    }
}
@media (min-width: 768px) {
    .business-card {
        .v-card {
            &__title,
            &__text {
                padding-left: 28px;
                padding-right: 28px;
            }
            &__title {
                padding-top: 40px;
                padding-bottom: 24px;
            }
            &__text {
                padding-bottom: 40px;
            }
        }
    }
    .service-card {
        .v-card {
            &__title,
            &__text {
                padding-bottom: 24px;
            }
        }
    }
    .bottom-banner {
        aspect-ratio: 1920/600;
        display: flex;
        align-items: center;
        &__title {
            font-size: 44px;
            > span {
                padding: 6px 0;
            }
        }
    }
}
</style>