<template>
    <div class="scrolldown-tail" />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.scrolldown-tail {
    color: var(--v-primary-base);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    background-color: currentColor;
    border-radius: 50%;
    margin-top: 20px;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        top: -20px;
        left: inherit;
        transform: inherit;
        display: block;
        width: 2px;
        height: 20px;
        background-color: inherit;
    }
}
@media (min-width: 1024px) {
    .scrolldown-tail {
        margin-top: 60px;
        &::before {
            top: -60px;
            height: 60px;
        }
    }
}
</style>