var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "guide-visual"
  }, [_c('v-card', {
    staticClass: "guide-visual__inner",
    attrs: {
      "img": _vm.image,
      "tile": "",
      "flat": ""
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', {
    staticClass: "client-title--xxxl ma-0 line-height-12",
    class: _vm.white ? 'white--text' : null
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]), _vm.textImage ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.textImage.image,
      "max-width": _vm.textImage.size,
      "alt": _vm.textImage.text
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-text font-size-md-20",
    class: _vm.white ? 'white--text' : null,
    domProps: {
      "innerHTML": _vm._s(_vm.texts)
    }
  })]), _vm.button ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "large": "",
      "rounded": "pill",
      "href": _vm.button.link,
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "font-size-md-20"
  }, [_vm._v(" " + _vm._s(_vm.button.text) + " ")]), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('scrolldown', {
    attrs: {
      "white": _vm.white
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }