var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.white ? 'scrolldown scrolldown--white' : 'scrolldown'
  }, [_c('div', {
    staticClass: "scrolldown__mouse"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }